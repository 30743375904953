import request from '@/utils/request'
// 今日学校签到排行榜
export function allSchoolSignList (data) {
  return request({
    url: '/statistics/bigdata/school/signcount/v1/list',
    method: 'post',
    params: data
  })
}
// 实习动态
export function dynamicPush (data) {
  return request({
    url: '/statistics/bigdata/realTimeDynamic/list',
    method: 'post',
    data
  })
}

// 一段日期内每天活跃用户数量列表
export function activeuser (data) {
  return request({
    url: '/statistics/bigdata/activeuser/count/v1/list',
    method: 'post',
    data
  })
}

export function byProvince (data) {
  return request({
    url: '/statistics/bigdata/school/num/byProvince',
    method: 'post',
    data
  })
}
/// /NEW
// 按省份分组统计学校的数量
// 统计学校数量
export function countArea (data) {
  return request({
    url: '/statistics/bigdata/school/num/byProvince',
    method: 'post',
    data
  })
}
// 今日签到，今日周报，今日上岗
export function todayv2 (data) {
  return request({
    url: '/statistics/bigdata/v2/taday',
    method: 'post',
    data
  })
}
// 省级数据渲染
export function IndicatorsQuantity (data) {
  return request({
    url: '/statistics/bigdata/v1/bigdata/taday',
    method: 'post',
    data
  })
}

// 学校签到数量
export function schoolList (data) {
  return request({
    url: '/statistics/bigdata/school/signcount/v2/list',
    method: 'post',
    data
  })
}

// 省市
export function listAll (data) {
  return request({
    url: '/system/gxydistrict/listAll',
    method: 'post',
    data
  })
}
