<template>
  <div class="home">
    <div class="top_header" id="Hheader">
      <div class="_w">
        <div class="logo" id="logo"></div>
        <div class="menu">
          <div class="menu_i" id="menu_i">首页</div>
          <div class="menu_i" id="menui1" @click="toSchool">
            <!-- <span @click="toSchool">合作学校</span> -->合作学校
          </div>
          <div class="menu_i" id="menui2" @click="toAbout">
            <!-- <span @click="toAbout">关于我们</span> -->关于我们
          </div>
          <div class="menu_i" id="menui3" @click="toRecruit">
            线上招聘
            <div @click.stop="all()" class="offline">全国招聘会</div>
            <div @click.stop="hubei()" class="offline">湖北招聘会</div>
            <div @click.stop="helongjiang()" class="offline">黑龙江招聘会</div>
          </div>
          <div class="menu_i" id="menui7" @click="toBig">
            <!-- <span @click="toBig">实习大数据</span> -->实习大数据
          </div>
          <div class="top_btn"></div>
          <!-- <div class="menu_i"><span>隐私政策</span></div> -->
          <!-- <button class="top_btn" @click="centerDialogVisible = true">
            99元体验套餐
          </button> -->
        </div>
      </div>
    </div>
    <div class="top" id="header1">
      <div class="_w"></div>

      <div class="block" id="box">
        <el-carousel trigger="click" indicator-position="left">
          <el-carousel-item v-for="item in [4, 5]" :key="item" class="carouselItem" :class="'topBanner' + item"
            @click="toRecruit(item)">
            <div style="height: 530px; width: 1903px; cursor: pointer" @click="toRecruit(item)"></div>
            <div class="_w">
              <div class="banner" v-show="item == 4">
                <div class="b_content">
                  <div class="content_top">找技能人才 就上蘑菇丁</div>
                  <div class="content_mid">
                    致力于为高职院校的实习就业提供一站式解决方案
                  </div>
                  <div class="content_bottom">
                    <button class="cb_btn">招假期工</button>
                    <button class="cb_btn">招实习生</button>
                    <button class="cb_btn">招正式工</button>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="login">
          <div class="switch" @click="toZhao"></div>
          <div class="loginBox">
            <p>企业登录</p>
            <!-- <img :src="getQRCode.goto" alt=""> -->
            <!-- <canvas id="QRCode_header"></canvas> -->
            <div style="width: 100%;display: flex;justify-content: center;">
              <wxlogin
                href="data:text/css;base64,LmxvZ2luUGFuZWwgLnRpdGxle2Rpc3BsYXk6bm9uZX0KLndhaXRpbmcgLmluZm97ZGlzcGxheTpub25lfQouaW1wb3dlckJveCAucXJjb2RleyAgICAKICAgIHdpZHRoOjEwLjM3NXJlbTsKICAgIG1hcmdpbi10b3A6IDA7CiAgICBib3JkZXI6IG5vbmU7Cn0KLm9sZC10ZW1wbGF0ZXsKd2lkdGg6NTAlOwp9"
                id="wxcode" theme="" appid="wxb00e2875006b2a74" scope="snsapi_login" loginType="wechatcompany"
                :redirect_uri="encodeURIComponent(
                  `http://zhaopin.moguding.net/login?uuid=${this.uuid}&thirdpart=wechatcompany${this.wxCode}`
                )
                  "></wxlogin>
            </div>

            <p class="wx">微信扫一扫，快速登录</p>
            <a href="http://zhaopin.moguding.net/login" target="_blank">立即注册</a>
          </div>
        </div>
      </div>
    </div>

    <div class="quotation">
      <div class="_w">
        <div class="card">
          <div class="line"></div>
          <div class="number">
            <img src="../assets/home_img/-m-school.png" alt="" />
            <p class="text">直达{{ this.schoolNum }}所</p>
            <p class="subText">高职/中职/职业本科院校</p>
          </div>
        </div>
        <div class="card">
          <div class="line"></div>
          <div class="number">
            <img src="../assets/home_img/-m-students.png" alt="" />
            <p class="text">{{ this.studentNum }}人</p>
            <p class="subText">真实技能人才库</p>
          </div>
        </div>
        <div class="card">
          <div class="line"></div>
          <div class="number">
            <img src="../assets/home_img/icon19.png" alt="" />
            <p class="text">{{ this.companyNum }}家</p>
            <p class="subText">企业入驻</p>
          </div>
        </div>
      </div>
    </div>

    <div class="dynamic">
      <div class="_w">
        <div class="title">实时动态</div>
        <!-- <marquee class="content" direction="up" scrollamount="3rem" loop="99"> -->
        <div class="dynamicBox">
          <!-- <vue-seamless-scroll
            :data="this.dynamic"
            :class-option="optionHover"
            class="seamless-warp"
          >
            <div
              v-for="item in this.dynamic"
              :key="item.id"
              style="text-align: left; margin: 0.5rem 0 0.5rem 0"
            >
              <span style="margin-right: 20px">{{ item.createTime }}</span>
              <strong>{{ item.schoolName }} {{ item.username }}</strong>
              <span>正在{{ item.businessType }}</span>
            </div>
          </vue-seamless-scroll> -->
          <vue-seamless-scroll :data="this.dynamic" :class-option="optionHover" class="seamless-warp" step:2>
            <div v-for="item in this.dynamic" :key="item.id" style="width: 30rem; display: flex">
              <div style="width: 20%; text-align: left; color: #999999">
                <span style="margin-right: 20px">{{ item.createTime }}</span>
              </div>
              <div style="
                  width: 50%;
                  text-align: left;
                  color: #333333;
                  margin: 5px 0 5px 0;
                ">
                <span>{{ item.schoolName }} {{ item.username }}</span>
              </div>
              <div style="width: 29%; text-align: right; color: #999999">
                <span style="">正在{{ item.businessType }}</span>
              </div>
            </div>
          </vue-seamless-scroll>
          <vue-seamless-scroll :data="this.dynamic2" :class-option="optionHover" class="seamless-warp">
            <div v-for="item in this.dynamic2" :key="item.id" style="width: 30rem; display: flex">
              <div style="width: 20%; text-align: left; color: #999999">
                <span style="margin-right: 20px">{{ item.createTime }}</span>
              </div>
              <div style="
                  width: 50%;
                  text-align: left;
                  color: #333333;
                  margin: 5px 0 5px 0;
                ">
                <span>{{ item.schoolName }} {{ item.username }}</span>
              </div>
              <div style="width: 29%; text-align: right; color: #999999">
                <span style="">正在{{ item.businessType }}</span>
              </div>
            </div>
          </vue-seamless-scroll>
        </div>

        <!-- <vue-seamless-scroll :data="this.dynamic" :class-option="optionHover" class="seamless-warp">
          <div v-for="item in this.dynamic" :key="item.id" style="text-align:left">
            <span>{{item.createTime}}</span>
            <strong>{{item.schoolName}} {{item.username}}</strong>
            <span>正在{{item.businessType}}</span>
          </div>
        </vue-seamless-scroll> -->
        <!-- </marquee> -->
        <!-- <div>
            <span>13:59:41</span>
            <strong>武汉职业技术学院 李*</strong>
            <span>正在搜索职位</span>
          </div>
          <div>
            <span>13:59:41</span>
            <strong>武汉职业技术学院 李*</strong>
            <span>正在搜索职位</span>
          </div>
          <div>
            <span>13:59:41</span>
            <strong>武汉职业技术学院 李*</strong>
            <span>正在搜索职位</span>
          </div>
          <div>
            <span>13:59:41</span>
            <strong>武汉职业技术学院 李*</strong>
            <span>正在搜索职位</span>
          </div>
          <div>
            <span>13:59:41</span>
            <strong>武汉职业技术学院 李*</strong>
            <span>正在搜索职位</span>
          </div> -->
      </div>
    </div>

    <div class="why">
      <div class="_w">
        <div class="up">
          <div class="left"></div>
          <div class="title">为什么选择蘑菇丁</div>
          <div class="right"></div>
        </div>
        <div class="guidBox">
          <el-row class="table">
            <el-col>
              <div class="grid-content guid">
                <p class="orgin">7年</p>
                <p class="small">历史沉淀</p>
              </div>
            </el-col>
            <el-col>
              <div class="grid-content guid">
                <p><span class="orgin">700</span><span class="add">+</span></p>
                <p class="small">合作学校</p>
              </div>
            </el-col>
            <el-col>
              <div class="grid-content guid">
                <p><span class="orgin">40%</span><span class="add">+</span></p>
                <p class="small">高职学校市占率</p>
              </div>
            </el-col>
          </el-row>
          <el-row class="table" style="margin-top: 2rem">
            <el-col>
              <div class="grid-content guid">
                <p><span class="orgin">100万</span><span class="add">+</span></p>
                <p class="small">企业入驻</p>
              </div>
            </el-col>
            <el-col>
              <div class="grid-content guid">
                <p>
                  <span class="orgin">500万</span><span class="add">+</span>
                </p>
                <p class="small">学生库</p>
              </div>
            </el-col>
            <el-col>
              <div class="grid-content guid">
                <p><span class="orgin">92.5%</span></p>
                <p class="small">对接成功率</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- <div class="car">
      <el-carousel trigger="click">
        <el-carousel-item v-for="item in 1" :key="item" class="carousel">
          <div class="carw">
            <div style="height: 18.75rem; width: 1903px; cursor: pointer" @click="tomidRecruit(item)"></div>
            <div class="_w"> -->
              <!-- <div class="content" v-show="item == 1">
                <p class="title">智己汽车2022春季校招正式开启</p>
                <p class="text" style="margin-top: 2.125rem">
                  期待与你，共同缔造
                </p>
                <p class="text">智能时代，汽车该有的样子</p>
              </div>
              <div class="carImg" v-show="item == 1"></div> -->
            <!-- </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div> -->

    <div class="serve">
      <div class="_w">
        <div class="up">
          <div class="left"></div>
          <div class="title">我们的服务</div>
          <div class="right"></div>
        </div>
        <div class="serveBox">
          <div class="ser">
            <div style="text-align: center">
              <p class="boxTitle">为企业提供从实习到就业一体化服务</p>
              <el-row :gutter="1" class="row">
                <el-col :span="7" class="col">
                  <el-card shadow="always" :body-style="{ padding: '0px' }" class="card">
                    <div class="img">
                      <img src="../assets/home_img/interShip.png" class="img" alt="" />
                    </div>
                    <p class="text">技能实习 校企双选</p>
                  </el-card>
                  <el-card shadow="always" :body-style="{ padding: '0px' }" class="card">
                    <div class="img">
                      <img src="../assets/home_img/zp.png" alt="" />
                    </div>
                    <p class="text">精准招聘 校企合作</p>
                  </el-card>
                </el-col>
                <el-col :span="10" class="col" style="margin-top: 2rem">
                  <el-card shadow="always" :body-style="{ padding: '0px' }" class="card">
                    <div class="img">
                      <img src="../assets/home_img/teach.png" alt="" />
                    </div>
                    <p class="text">定向培养 输送人才</p>
                  </el-card>
                  <el-card shadow="always" :body-style="{ padding: '0px' }" class="card">
                    <div class="img">
                      <img src="../assets/home_img/person.png" alt="" />
                    </div>
                    <p class="text">引才引智 产业转型</p>
                  </el-card>
                </el-col>
              </el-row>
            </div>

            <div class="serveImg"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="school">
      <div class="_w">
        <div class="more">
          <p>我们的合作院校</p>
          <p>行业内拥有全产业链自主研发体系的公司</p>
          <button @click="toSchool" style="cursor: pointer">
            查看更多学校
          </button>
        </div>
        <div class="schoolIco">
          <el-row :gutter="0">
            <el-col :span="6">
              <div @click="toGZJT" style="cursor: pointer">
                <img src="../assets/home_img/1.png" alt="" />
              </div>
            </el-col>
            <el-col :span="6">
              <div @click="toKMYJ" style="cursor: pointer">
                <img src="../assets/home_img/2.png" alt="" />
              </div>
            </el-col>
            <el-col :span="6">
              <div @click="toSDSY" style="cursor: pointer">
                <img src="../assets/home_img/3.png" alt="" />
              </div>
            </el-col>
            <el-col :span="6">
              <div @click="toSXCZ" style="cursor: pointer">
                <img src="../assets/home_img/4.png" alt="" />
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col :span="6">
              <div @click="toWZ" style="cursor: pointer">
                <img src="../assets/home_img/5.png" alt="" />
              </div>
            </el-col>
            <el-col :span="6">
              <div @click="toXJNY" style="cursor: pointer">
                <img src="../assets/home_img/6.png" alt="" />
              </div>
            </el-col>
            <el-col :span="6">
              <div @click="toCSMZ" style="cursor: pointer">
                <img src="../assets/home_img/7.png" alt="" />
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <img src="../assets/home_img/8.png" alt="" />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="company">
      <div class="_w">
        <div class="up">
          <div class="left"></div>
          <div class="title">入驻企业</div>
          <div class="right"></div>
        </div>
      </div>
      <div class="companyIco">
        <el-row :gutter="0">
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/huawei.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/jingdongwuliu.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/bishengke.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/dikalong.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/haixin.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/huarun.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/beidou.png" alt="" />
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/kewosi.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/quchenshi.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/jiliqiche.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/wangwang.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/zhongguoshiyou.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/kongke.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/furijituan.png" alt="" />
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/manner.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/kfc.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/woerma.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/zhongtie.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/xingbake.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/youdaguangdian.png" alt="" />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <img src="../assets/home_img/haiwangxingchen.png" alt="" />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-dialog :visible.sync="centerDialogVisible" width="400px" center>
      <div class="dialog">
        <div>
          <p class="title">蘑菇丁企业版</p>
          <p class="subTitle">找技能人才，就上蘑菇丁</p>
          <img src="../assets/home_img/99.png" alt="" style="width: 200px; height: 200px" />
          <p class="text">扫码立即联系专属服务官</p>
        </div>
        <div class="bottom">
          <img src="../assets/home_img/logo.png" alt="" />
        </div>
      </div>
    </el-dialog>

    <el-dialog title="致亲爱的老师、同学：" :visible.sync="dialogVisible" width="100%" center class="el-dialog__center1">
      <p class="toTeacherAndStudent">
        各位老师、同学们，moguding.net将承载公司招聘业务，
        请需要使用或登录实习管理系统的老师、 同学通过(<a href="https://p3.gongxueyun.com/login" target="_blank"
          style="color: blue; text-decoration: none">p3.gongxueyun.com</a>)地址进入，十分感谢
      </p>
      <span slot="footer" class="dialog-footer">
        <a href="https://p3.gongxueyun.com/login" target="_blank" class="dilog_fotter">立即前往>></a>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import countTo from 'vue-count-to'
// import $ from 'jquery'
import { dynamicPush, IndicatorsQuantity } from "@/api/realTimeMonitoring";
// import { getTopics } from '../../config/api'
// import uuid from 'uuid'
import QRCode from "qrcode"; //引入生成二维码插件
import axios from "axios";
import wxlogin from "vue-wxlogin";
export default {
  name: "HomeView",
  data() {
    return {
      centerDialogVisible: false,
      schoolNum: 0, //学校总数
      studentNum: 0, //学生总数
      companyNum: 0, //企业总数,
      dynamic: [],
      dynamic2: [],
      uuid: "",
      QRCodeMsg: "",
      dialogVisible: false,
    };
  },
  components: {
    wxlogin,
  },
  mounted() {
    var Hheader = document.getElementById("Hheader").offsetHeight;
    var contentHeight = document.getElementById("box").offsetHeight;
    document.body.onscroll = () => {
      if (document.documentElement.scrollTop >= contentHeight - Hheader) {
        // document.getElementById('Hheader').style.background='white'
        // document.getElementById('Hheader').style.color='black !important'
        // document.getElementById('Hheader').style.width='100%'
        document.getElementById("Hheader").id = "Hheader2";
        document.getElementById("logo").id = "menu2";
        document.getElementById("menui1").id = "menui4";
        document.getElementById("menu_i").id = "menu_i2";
        document.getElementById("menui2").id = "menui5";
        document.getElementById("menui3").id = "menui6";
        document.getElementById("menui7").id = "menui8";
      } else {
        // document.getElementById('Hheader').style.background='transparent'
        document.getElementById("Hheader2").id = "Hheader";
        document.getElementById("menu2").id = "logo";
        document.getElementById("menui4").id = "menui1";
        document.getElementById("menu_i2").id = "menu_i";
        document.getElementById("menui5").id = "menui2";
        document.getElementById("menui8").id = "menui7";
        document.getElementById("menui6").id = "menui3";
      }
    };
    // axios({
    //   url: 'https://api.moguding.net:9000/statistics/bigdata/realTimeDynamic/list',
    //   method: 'post',
    //   query: {
    //     currPage: 1,
    //     pageSize: 40,
    //     province: ''
    //   }
    // }).then((res) => {
    //   console.log(res)
    // })
    const uuid = require("uuid");
    this.uuid = uuid.v4();
    // console.log('uuid.v4()', this.uuid)
    IndicatorsQuantity({ province: "" }).then((data) => {
      this.schoolNum = data.data.schoolNum;
      this.studentNum = data.data.studentNum;
      this.companyNum = data.data.countComNum;
    });
    dynamicPush({ province: "", currPage: 1, pageSize: 40 }).then((data) => {
      // this.dynamic = data.data
      this.dynamic = data.data.map((item) => {
        return {
          ...item,
          createTime: item.createTime.split(" ")[1],
        };
      });
      this.dynamic2 = data.data;
    });
    setTimeout(() => {
      dynamicPush({ province: "", currPage: 1, pageSize: 40 }).then((data) => {
        this.dynamic2 = data.data.map((item) => {
          return {
            ...item,
            createTime: item.createTime.split(" ")[1],
          };
        });
      });
    }, 500);
    document.getElementById("menu_i").className = "active";
    this.getQRCode();
    this.getUrl();
  },
  methods: {
    all() {
      this.$router.push("/nationwide");
    },
    hubei() {
      this.$router.push("/recruit");
    },
    helongjiang() {
      this.$router.push("/blackriverView");
    },
    toZhao() {
      window.open("http://zhaopin.moguding.net/login");
    },
    toGZJT() {
      window.open("http://www.gzjtzy.net/");
    },
    toKMYJ() {
      window.open("http://www.kmyz.edu.cn/");
    },
    toSDSY() {
      window.open("http://www.sict.edu.cn/");
    },
    toSXCZ() {
      window.open("https://www.sxftc.edu.cn/");
    },
    toWZ() {
      window.open("https://www.wtc.edu.cn/");
    },
    toXJNY() {
      window.open("http://www.xjnzy.edu.cn/");
    },
    toCSMZ() {
      window.open("http://www.csmzxy.edu.cn/");
    },

    handleClose() { },
    toAbout() {
      this.$router.push("./about");
    },
    toSchool() {
      this.$router.push("/school");
    },
    toBig() {
      // window.location.go('https://p3bigdata.gongxueyun.com')
      // console.log(window.open)
      window.open("https://p3bigdata.gongxueyun.com");
    },
    tomidRecruit(item) {
      if (item == 1) {
        this.$router.push("/blackriverView");
      } else if (item === 2) {
        this.$router.push("/nationwide");
      }
    },
    toRecruit(item) {
      if (item == 1) {
        this.$router.push("/blackriverView");
      } else if (item === 2) {
        this.$router.push("/nationwide");
      } else if (item === 3) {
        this.$router.push("/recruit");
      } else if (item === 5) {
        window.open("https://p3.gongxueyun.com");
      }
    },
    getQRCode() {
      const appid = "wxb00e2875006b2a74";
      const myDomain = encodeURIComponent("http://zhaopin.moguding.net/");
      // const uuid = this.uuid
      // console.log('12321', this.uuid)
      const thirdpart = "wechatcompany";
      const url = `${myDomain}login?uuid=${this.uuid}&thirdpart=${thirdpart}`;

      const goto =
        "https://open.weixin.qq.com/connect/qrconnect?appid=" +
        appid +
        "&redirect_uri=" +
        url +
        "&response_type=code&scope=snsapi_login#wechat_redirect";
      // window.location.href = goto
      // console.log('url', window.location.href)
      // axios({
      //   url: goto,
      //   method: 'get'
      // }).then((res) => {
      //   console.log(res)
      // })
      // console.log("随机码", goto);
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 12, //二维码留白边距
        width: 180, //宽
        height: 180, //高
        text: goto, //二维码内容
        color: {
          dark: "#333333", //前景色
          light: "#fff", //背景色
        },
      };
      this.QRCodeMsg = goto; //生成的二维码为URL地址js
      let msg = document.getElementById("QRCode_header");
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, this.QRCodeMsg, opts, function (error) {
        // console.log(error)
      });
    },
    getUrl() { },
  },
  computed: {
    optionHover() {
      return {
        hoverStop: false, //鼠标悬停关闭

        singleWidth: 0, // 单行停顿的宽度(默认值0是无缝不停止的滚动)

        // singleHeight: 26, //单行停顿间距(不需要停顿不设置)

        // waitTime: 2500, //单行停顿时间(不需要停顿不设置)

        step: 0.5, //向上滚动不停顿

        limitMoveNum: 2, // 开始无缝滚动的数据量

        direction: 1, // 0向下 1向上 2向左 3向右

        openWatch: true, // 开启数据实时监控刷新dom
      };
    },
    realTime() {
      return 1;
    },
  },
  //监听路由可以检测返回的code
  watch: {
    $route: {
      handler: function (route) {
        this.wxCode = this.$route.query.code || "";
        //本地存储code是因为从其他页面返回vue页面存在缓存需要自定义刷新
        if (this.wxCode == localStorage["wxCode"] && this.wxCode != "") {
          window.location.href = this.redirect_uri; //回调地址
        } else {
          localStorage.setItem("wxCode", this.wxCode); //设置b为"isaac"
        }
        if (this.wxCode) {
          let params = {
            code: this.wxCode,
            state: "",
          };
          wechatLogin(params)
            .then((res) => {
              if (res.status) {
                //返回参数如果绑定微信返回token正常存储登录未绑定账号返回unionid绑定当然也可以以微信信息为主题自动生成账号
              }
            })
            .catch(() => {
              //返回失败因为二维码已经被使用过所以需要刷新重新获取
              window.location.href = this.redirect_uri;
            });
        }
      },
      immediate: true,
    },
  },
};
</script>
<style>
.impowerBox .qrcode {
  border: none;
}
</style>
<style lang='less'  scoped>
* {
  padding: 0;
}

html {
  overflow-y: scroll;
}

.home {
  display: flex;
  align-items: center;
  flex-direction: column;
}

._w {
  width: 63%;
  //   border: 1px solid red;
}

#Hheader2 {
  background-color: white;
  // color: black;
}

#Hheader {
  background: transparent;
}

#menu2 {
  width: 8.125rem;
  height: 2.375rem;
  background: url("../assets/home_img/logo_black.png") no-repeat;
  background-size: cover;
}

.top {
  width: 100%;
  // height: 33.5rem;
  // background: url("../assets/home_img/top_banner.png") no-repeat;
  // background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 3;
  //   border: 10px solid red;
}

.block {
  width: 100%;
  height: 33.5rem;
  position: relative;
}

// .topBanner1{
//   background: url("../assets/home_img/top_blackriver.jpg") no-repeat;
//   background-size: cover;
//   color: #191919;
// }
// .topBanner2{
//   background: url("../assets/allzhaopin.jpg") no-repeat;
//   background-size: cover;
//   color: #191919;
// }
// .topBanner3{
//   background: url("../assets/home_img/hubeitop_banner1.png") no-repeat;
//   background-size: cover;
//   color: #191919;
// }
.topBanner4 {
  background: url("../assets/home_img/top_banner.png") no-repeat;
  background-size: cover;
  color: #191919;
}

.topBanner5 {
  background: url("../assets/home_img/top_banner2.png") no-repeat;
  background-size: cover;
  color: #191919;
}

//::v-deep .top .block .el-carousel__item:nth-of-type(4) {
//  background: url("../assets/home_img/top_banner.png") no-repeat;
//  background-size: cover;
//  color: #191919;
//}
//::v-deep .top .block .el-carousel__item:nth-of-type(3) {
//  background: url("../assets/home_img/hubeitop_banner1.png") no-repeat;
//  background-size: cover;
//  color: #191919;
//}
//::v-deep .top .block .el-carousel__item:nth-of-type(2) {
//  background: url("../assets/allzhaopin.jpg") no-repeat;
//  background-size: cover;
//  color: #191919;
//}
//::v-deep .top .block .el-carousel__item:nth-of-type(5) {
//  background: url("../assets/home_img/top_banner2.png") no-repeat;
//  color: #191919;
//  background-size: cover;
//}
//::v-deep .top .block .el-carousel__item:nth-of-type(1) {
//  background: url("../assets/home_img/top_blackriver.jpg") no-repeat;
//  color: #191919;
//  background-size: cover;
//}

/* ::v-deep .top .block .el-carousel__item:nth-child(n + 3) {
  background: url("../assets/home_img/top_banner2.png") no-repeat;
  background-size: cover;
} */
/*::v-deep .car .el-carousel__item:nth-child(3) {
  background: url("../assets/home_img/see.jpg");
  background-size: contain;
}*/

// ::v-deep .car .el-carousel__item:nth-child(4) {
//   background: url("../assets/home_img/carbg.jpg");
//   background-size: contain;
// }
// ::v-deep .car .el-carousel__item:nth-child(3) {
//   background: url("../assets/home_img/blackriver.jpg");
//   background-size: contain;
// }
::v-deep .el-carousel {
  width: 100%;
  height: 33.5rem;
  position: relative;
}

::v-deep .el-carousel__container {
  // height: 33.5rem;
}

::v-deep .top .block .el-carousel__indicators {
  position: absolute;
  bottom: 3rem;
  left: 10rem;
}

::v-deep .el-carousel__indicator {
  //指示器
  border-radius: 50%;
}

.carouselItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 513px;
}

.top_header {
  width: 100%;
  height: 5rem;
  // background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 999;
}

.top_header ._w {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top_header .logo {
  width: 8.125rem;
  height: 2.375rem;
  background: url("../assets/home_img/logo.png") no-repeat;
  background-size: cover;
}

/* #logo{
  background: url("../assets/home_img/logo_black.png") no-repeat;
  background-size: cover;
} */
.top_header .menu {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

#menui1,
#menui2,
#menui3,
#menui7,
#menu_i {
  font-size: 1rem;
  width: 9.375rem;
  height: 2.5rem;
  text-align: center;
  line-height: 2.5rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #ffffff;
  cursor: pointer;
}

#menui4,
#menui5,
#menui6,
#menui8,
#menu_i2 {
  font-size: 1rem;
  width: 9.375rem;
  height: 2.5rem;
  text-align: center;
  line-height: 2.5rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: black;
  cursor: pointer;
}

#menui6 .offline {
  display: none;
}

#menui6:hover .offline {
  display: block;
}

.top_header .menu .menu_i span:hover {
  color: #ffb20c;
}

.top_header .menu .top_btn {
  width: 9.375rem;
  height: 2.5rem;
  font-size: 1rem;
  // font-family: OPPOSans;
  // font-weight: normal;
  // color: #191919;
  // border-radius: 0.25rem;
  // background-color: #ffb20c;
  // border: none;
  // cursor: pointer;
}

.top .banner {
  width: 75rem;
  height: 25.625rem;
  display: flex;
  justify-content: space-between;
}

.top .banner .b_content {
  width: 36.875rem;
  height: 12.5rem;
  margin-top: 8rem;
}

.top .banner .b_content .content_top {
  font-size: 3.5rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #ffffff;
}

.top .banner .b_content .content_mid {
  font-size: 1.125rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #ffffff;
  margin-top: 1.625rem;
}

.top .banner .b_content .content_bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.top .banner .b_content .content_bottom .cb_btn {
  width: 10.625rem;
  height: 2.5rem;
  border: 1px solid #ffffff;
  border-radius: 0.25rem;
  background: rgba(0, 0, 0, 0);
  font-size: 1rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #ffffff;
  cursor: pointer;
}

.top .login {
  width: 21.75rem;
  height: 21.625rem;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 1.5rem;
  position: absolute;
  top: 5rem;
  right: 22rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 5;
}

.top .login .switch {
  width: 10.75rem;
  height: 4rem;
  background: url("../assets/home_img/mmSignin.png") no-repeat;
  background-size: cover;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  cursor: pointer;
}

.top .login .userPass {
  width: 5.6875rem;
  height: 1.25rem;
  background: url("../assets/home_img/signin.png") no-repeat;
  background-size: cover;
  position: absolute;
  top: 0.5rem;
  left: 4.625rem;
}

.top .login .loginBox {
  // text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.top .login .loginBox p:nth-child(1) {
  font-size: 1.125rem;
  ;
  font-family: OPPOSans;
  font-weight: normal;
  color: #333333;
  margin-top: 1.2612rem;
}

.top .login .loginBox #wxcode {
  width: 10.375rem;
  height: 10.375rem;
  // width: 10%;
  // height: 10%;
}

.top .login .loginBox .wx {
  font-size: 1rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #666666;
  // line-height: 3rem;
  opacity: 0.8;
}

.top .login .loginBox a {
  font-size: 0.875rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #333;
  text-decoration: none;
  background-color: #FFB20C;
  width: 228px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-top: 1rem;
  border-radius: 0.25rem;
}

::v-deep(.old-template) {
  // width: 166px !important;
}

.quotation {
  width: 100%;
  height: 23.125rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: -5rem;
  //   border: 1px solid red;
}

.quotation ._w {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  //   flex-direction: column;
}

.quotation .card {
  width: 21.75rem;
  height: 18.75rem;
  background: #ffffff;
  box-shadow: 0px -6px 26px 5px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  z-index: 4;
}

.quotation .card .line {
  width: 21.75rem;
  height: 0.625rem;
  background: #ffb20c;
  border-radius: 8px 8px 0 0;
}

.quotation .card .number {
  width: 21.75rem;
  height: 18.125rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  // border: 1px solid red;
}

.quotation .card .number img {
  width: 5.375rem;
  height: 5.375rem;
}

.quotation .card .number .text {
  font-size: 2rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #191919;
  line-height: 3rem;
}

.quotation .card .number .subText {
  font-size: 1rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #666666;
  line-height: 3rem;
}

.dynamic {
  width: 100%;
  height: 22.75rem;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // border: 1px solid red;
}

.dynamic ._w {
  display: flex;
  align-items: center;
  //   justify-content: center;
  flex-direction: column;
  text-align: center;
  //   border: 1px solid red;
}

.dynamic ._w .dynamicBox {
  width: 100%;
  height: 17.75rem;
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
}

.dynamic .title {
  font-size: 1.5rem;
  font-family: OPPOSans;
  font-weight: bold;
  color: #333333;
  line-height: 3rem;
}

.dynamic ._w .content {
  //   display: block;
  width: 57.1875rem;
  height: 8.5rem;
}

.dynamic ._w .content div {
  float: left;
  margin-left: 6rem;
}

.why {
  width: 100%;
  height: 30.625rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //   border: 1px solid red;
}

.why .up {
  height: 2.75rem;
  display: flex;
  align-items: center;
  //   justify-content: space-between;
  //   flex-direction: column;
  //   border: 1px solid red;
}

.why .up .left {
  width: 24.125rem;
  height: 2px;
  background: url("../assets/home_img/copy9.png") no-repeat;
  background-size: cover;
}

.why .up .right {
  width: 24.125rem;
  height: 2px;
  background: url("../assets/home_img/copy8.png") no-repeat;
  background-size: cover;
}

.why .up .title {
  width: 22.25rem;
  font-size: 2.75rem;
  font-family: OPPOSans;
  font-weight: bold;
  color: #333333;
  margin: 0 2rem 0 2rem;
}

.why .guidBox {
  height: 16.5625rem;
  //   border: 1px solid red;
  margin-top: 2rem;
}

.why .guidBox .table {
  display: flex;
  align-items: center;
  justify-content: space-around;
  //   border: 1px solid red;
  //   flex-direction: column;
}

::v-deep .el-col-24 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.why .guidBox .table .guid {
  width: 10.25rem;
  height: 6.1875rem;
  //   border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
  //   border: 1px solid blue;
  flex-direction: column;
}

.why .guidBox .table .orgin {
  width: 5.25rem;
  height: 3.1875rem;
  font-size: 3rem;
  font-family: OPPOSans;
  font-weight: bold;
  color: #ffb20c;
}

.why .guidBox .table .add {
  width: 0.75rem;
  height: 0.75rem;
  font-size: 1.5rem;
  font-family: OPPOSans;
  font-weight: bold;
  color: #ffb20c;
}

.why .guidBox .table .small {
  font-size: 1rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #333333;
}

//轮播图
.car {
  width: 100%;
  height: 18.75rem;
  background: #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // border: 1px solid red;
}

// .el-carousel__item:nth-child(2n) {
//   background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n + 1) {
//   background-color: #d3dce6;
// }
.carw {
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
}

.car ._w {
  height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep .car ._w .el-carousel {
  height: 100%;
}

::v-deep .car .el-carousel .el-carousel__container {
  height: 240px;
}

.car .carousel {
  width: 100%;
  height: 18.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid red;
}

.car .content .title {
  font-size: 2rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #fe5e10;
}

.car .content .text {
  font-size: 1.5rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #666666;
}

.car .carImg {
  width: 35.5rem;
  height: 10.8125rem;
  background: url("../assets/home_img/car.png") no-repeat;
  background-size: cover;
}

.serve {
  width: 100%;
  height: 37.5rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  //   justify-content: center;
  flex-direction: column;
}

.serve .up {
  height: 2.75rem;
  display: flex;
  align-items: center;
  margin-top: 3rem;
  //   justify-content: space-between;
  //   flex-direction: column;
  //   border: 1px solid red;
}

.serve .up .left {
  width: 24.125rem;
  height: 2px;
  background: url("../assets/home_img/copy9.png") no-repeat;
  background-size: cover;
}

.serve .up .right {
  width: 24.125rem;
  height: 2px;
  background: url("../assets/home_img/copy8.png") no-repeat;
  background-size: cover;
}

.serve .up .title {
  width: 22.25rem;
  font-size: 2.75rem;
  font-family: OPPOSans;
  font-weight: bold;
  color: #333333;
  text-align: center;
  //   margin: 0 2rem 0 2rem;
}

.serve .serveBox {
  width: 100%;
  height: 23.5rem;
  margin-top: 2rem;
}

.serve .serveBox .boxTitle {
  font-size: 1.5rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #191919;
  line-height: 3rem;
  margin-bottom: 0.8rem;
}

.serve .serveBox .row {
  width: 35.375rem;
}

.serve .serveBox .col {
  width: 35.375rem;
  display: flex;
  justify-content: space-between;
}

.serve .serveBox .col img {
  // border: 1px solid red;
  width: 100%;
}

::v-deep .el-card__body {
  width: 16.375rem;
  height: 7.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.serve .ser {
  display: flex;
}

.serve .serveBox .card .img {
  width: 2.875rem;
  height: 2.875rem;
}

.serve .serveImg {
  width: 33.0625rem;
  height: 23.3125rem;
  margin-left: 3rem;
  background: url("../assets/home_img/service_pic.png") no-repeat;
  background-size: cover;
}

.school {
  width: 100%;
  height: 36.625rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: url("../assets/home_img/school_bg.png");
  background-size: cover;
}

.school ._w {
  display: flex;
}

.school .more {
  width: 20.25rem;
  height: 28.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 2rem;
}

.school .more p:nth-child(1) {
  font-size: 2.25rem;
  font-family: OPPOSans;
  font-weight: 500;
  color: #ffffff;
}

.school .more p:nth-child(2) {
  font-size: 1.125rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #ffffff;
  margin: 1.5625rem 0 3.0625rem 0;
}

.school .more button {
  width: 11.625rem;
  height: 3.125rem;
  border: 1px solid #ffffff;
  border-radius: 0.25rem;
  background: none;
  font-size: 1rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #ffffff;
}

.school .schoolIco {
  width: 47.625rem;
  height: 28.6875rem;
}

::v-deep .school .schoolIco .el-col-6 {
  width: 11.5625rem;
  height: 13.8125rem;
  // background-color: rgba(255, 255, 255, 0.1);
  margin-right: 0.4375rem;
}

::v-deep .school .schoolIco .el-col-6:last-child {
  margin-right: 0rem;
}

::v-deep .school .schoolIco .el-row:nth-child(1) {
  margin-bottom: 1.0625rem;
}

::v-deep .school .schoolIco img {
  width: 100%;
}

.company {
  width: 100%;
  height: 43.75rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  // justify-content: center;
}

.company .up {
  height: 2.75rem;
  display: flex;
  align-items: center;
  margin-top: 3rem;
  //   justify-content: space-between;
  //   flex-direction: column;
  //   border: 1px solid red;
}

.company .up .left {
  width: 24.125rem;
  height: 2px;
  background: url("../assets/home_img/copy9.png") no-repeat;
  background-size: cover;
}

.company .up .right {
  width: 24.125rem;
  height: 2px;
  background: url("../assets/home_img/copy8.png") no-repeat;
  background-size: cover;
}

.company .up .title {
  width: 22.25rem;
  font-size: 2.75rem;
  font-family: OPPOSans;
  font-weight: bold;
  color: #333333;
  text-align: center;
  //   margin: 0 2rem 0 2rem;
}

.company .companyIco {
  margin-top: 3.75rem;
}

::v-deep .company .companyIco .el-col-6 {
  width: 14.75rem;
  height: 7.5rem;
  vertical-align: middle;
}

::v-deep .company .companyIco .el-col-6 img {
  width: 100%;
}

::v-deep .company .companyIco .el-row:nth-child(2) {
  margin: 2.375rem 0 2.375rem 0;
}

.dialog {
  height: 500px;
  background: #ffffff;
  border-radius: 0.25rem;
  text-align: center;

  .title {
    font-size: 1.5rem;
    font-family: OPPOSans;
    font-weight: bold;
    color: #191919;
    margin-top: 3.5rem;
  }

  .subTitle {
    font-size: 1rem;
    font-family: OPPOSans;
    font-weight: normal;
    color: #666666;
    margin: 1rem 0 3.5rem 0;
  }

  .text {
    font-size: 1.125;
    font-family: OPPOSans;
    font-weight: normal;
    color: #333333;
    margin: 1.5rem 0 5.375rem 0;
  }

  .bottom {
    width: 100%;
    height: 3.5rem;
    background: #2a2313;
    border-radius: 0 0 4px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog--center {
  border-radius: 20px;
}

::v-deep .el-dialog__title {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}

.toTeacherAndStudent {
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 2.75rem;
  text-indent: 2em;
}

::v-deep .el-dialog__header {
  background-color: #ffb20c;
  border-radius: 20px 20px 0 0;
  padding-bottom: 30px;
  padding: 15px 0 15px 0;
}

::v-deep .el-dialog__footer {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffb20c;
  line-height: 49px;
  border-radius: 0 0 20px 20px;
  cursor: pointer;
}

.el-dialog__center1 {
  width: 50%;
  height: 60%;

  position: fixed;
  top: 15%;
  left: 25%;
}

.dilog_fotter {
  color: #ffb20c;
  text-decoration: none;
}

::v-deep .el-icon-close:before {
  color: #ffffff;
  font-size: 1.5rem;
}

.seamless-warp {
  height: 14.75rem;
  overflow: hidden;
  // border: 1px solid red;
  // background: linear-gradient(#f5f5f5 20%, #f5f5f5 75%, rgba(129, 127, 127, 0.2));
  // box-shadow: 10px 40px 117px #f5f5f5;
}

.active {
  font-size: 1rem;
  width: 9.375rem;
  height: 2.5rem;
  text-align: center;
  line-height: 2.5rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #ffffff;
  position: relative;
  cursor: pointer;
}

::v-deep .el-dialog__wrapper:nth-child {
  position: fixed;
  top: 15%;
  left: 25%;
}

@media screen and (min-width: 969px) and (max-width: 1800px) {

  .block {
    width: 100%;
    height: 23.5rem;
    position: relative;
  }

  ::v-deep .el-dialog__center1 {
    height: 64%;
  }

  .quotation {
    margin-top: 5rem;
  }

  //::v-deep .top .block .el-carousel__item:nth-child(2n + 1) {
  //  background: url("../assets/home_img/top_banner3.png") no-repeat;
  //  background-size: cover;
  //}
  .carouselItem[data-v-9ea40744] {
    height: 376px;
  }

  ::v-deep .el-carousel__container {
    position: relative;
    height: 213px;
  }
}

@media screen and (max-width: 600px) {
  /* .top .top_header .menu .menu_i,.active{ 
   font-size: 5px ;
   color: red;
   } */
  font-size: 5px;

  .el-dialog__center1 {
    // border: 1px solid red;
    font-size: 10px;
    height: 15px;
  }

  ::v-deep .el-dialog--center .el-dialog__header {
    height: 30px;
    padding: 15px 0 0px 0;
  }

  ::v-deep .el-dialog--center .el-dialog__headerbtn {
    position: absolute;
    top: 0px;
  }

  ::v-deep .el-dialog__header .el-dialog__title {
    font-size: 15px;
    height: 15px;
    // border: 1px solid red;
  }

  ::v-deep .el-dialog--center .el-dialog__footer {
    height: 45px;
    line-height: 24px;
  }

  ::v-deep .carouselItem {
    height: 100px;
  }

  .carouselItem[data-v-9ea40744] {
    height: 100px;
  }

  ::v-deep .dilog_fotter {
    font-size: 15px;
  }

  .toTeacherAndStudent {
    font-size: 10px;
    line-height: 15px;
  }

  .dialog .bottom img {
    width: 10%;
  }
}

#menui3:hover .offline {
  display: block;
}

#menui3 .offline {
  background-color: #fff;
  color: #000;
  display: none;
}
</style>