<template>
  <el-container>
    <el-main style="padding: 0">
      <router-view />
    </el-main>
    <el-footer>
      <div class="footer">
        <div class="_w">
          <div class="top">
            <div class="about">
              <p>关于我们</p>
              <p>掌淘网络科技（上海）有限公司</p>
              <p>全国职业教育实践育人产教联盟理事单位</p>
              <p>电 话：400-888-3518</p>
              <p>邮 箱：service@moguding.com</p>
              <p>地 址：上海市徐汇区345弄27号德必易园D座101</p>
            </div>
            <div class="qrcode">
              <div class="wechat">
                <img src="./assets/home_img/mgdWechat.jpg" alt="" />
                <p>微信公众号</p>
              </div>
              <div class="wechat">
                <img src="./assets/home_img/company_contact.jpg" alt="" />
                <p>扫码找工作</p>
              </div>
            </div>
          </div>
          <div class="record">
            <p>
              <span
                >Copyright©2012-2021 掌淘网络科技（上海）有限公司 AII Right Reserved
                沪ICP备15057510号-3</span
              >
              <i class="police"></i>
              <span>沪公网安备 31011502014769号</span>
            </p>
            <p>企业服务热线和举报投诉 400 888 3518（8：30 - 18：00）</p>
          </div>
        </div>
      </div>
    </el-footer>
    <div class="always" v-show="always">
      <p><span style="color: #fe5e10"></span> 专属服务官，助你获得更多投递！</p>
      <button @click="centerDialogVisible = true">立刻联系</button>
      <p class="close" @click="close">X</p>
    </div>
    <el-dialog :visible.sync="centerDialogVisible" width="400px" center>
      <div class="dialog">
        <div>
          <p class="title">蘑菇丁企业版</p>
          <p class="subTitle">找技能人才，就上蘑菇丁</p>
          <img
            src="./assets/home_img/99.jpg"
            alt=""
            style="width: 200px; height: 200px"
          />
          <p class="text">扫码立即联系专属服务官</p>
        </div>
        <div class="bottom">
          <img src="./assets/home_img/logo.png" alt="" />
        </div>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      always: true,
      centerDialogVisible: false,
    };
  },
  components: {},
  methods: {
    close() {
      this.always = false;
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
* {
  padding: 0;
  margin: 0;
}
html {
  overflow-y: scroll;
}
::v-deep .el-main {
  overflow: hidden;
}
._w {
  width: 63%;
}
::v-deep .el-footer {
  padding: 0;
}
.footer {
  width: 100%;
  height: 32.75rem;
  background: #272625;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
.footer .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer .top .about {
  color: #ffffff;
  font-size: 0.875rem;
}
.footer .top .about p:nth-child(1) {
  font-size: 1rem;
}
.footer .top .about p {
  margin-bottom: 1rem;
}
.footer .top .qrcode {
  width: 22.5rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.footer .top .qrcode .wechat {
  width: 6.125rem;
  height: 10rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
.footer .top .qrcode .wechat img {
  width: 6.125rem;
  height: 6.125rem;
  border-radius: 0.25rem;
}
.footer .top .qrcode .wechat p {
  font-size: 0.875;
  font-family: OPPOSans;
  font-weight: normal;
  color: #ffffff;
  line-height: 3rem;
}
.footer .top .qrcode .Discount {
  width: 11rem;
  height: 10rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
.footer .top .qrcode .Discount img {
  width: 6.125rem;
  height: 6.125rem;
  border-radius: 0.25rem;
}
.footer .top .qrcode .Discount p {
  font-size: 0.875;
  font-family: OPPOSans;
  font-weight: normal;
  color: #ffffff;
  line-height: 3rem;
}
.footer .record {
  margin-top: 4rem;
  text-align: center;
  vertical-align: middle;
}
.footer .record p {
  font-size: 0.75rem;
  font-family: OPPOSans;
  font-weight: normal;
  color: #ffffff;
  margin: 0.75rem 0 0.75rem 0;
}
.footer .record .police {
  display: inline-block;
  width: 1.125rem;
  height: 1.1875rem;
  margin: 0.75rem 0.75rem -0.25rem 3rem;
  background: url("./assets/home_img/police.png") no-repeat;
  background-size: cover;
}
.always {
  width: 100%;
  height: 6rem;
  background: #ffffff;
  font-size: 1.625rem;
  font-family: OPPOSans;
  font-weight: regular;
  color: #333333;
  line-height: 3rem;
  vertical-align: middle;
  display: flex;
  align-items: center;
  // flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 0;
  z-index: 4;
}
.always button {
  width: 11.625rem;
  height: 3.125rem;
  background: #ffb20c;
  border-radius: 5px;
  border: none;
  margin: 0 1.6875rem 0 4rem;
  cursor: pointer;
}
.always p,
button {
  display: inline-block;
  font-size: 1.125rem;
  font-family: OPPOSans;
  font-weight: regular;
  color: #000000;
}
.always .close {
  color: #d1d1d1;
  font-size: 1.3125rem;
  cursor: pointer;
}
.dialog {
  height: 500px;
  background: #ffffff;
  border-radius: 0.25rem;
  text-align: center;

  .title {
    font-size: 1.5rem;
    font-family: OPPOSans;
    font-weight: bold;
    color: #191919;
    margin-top: 3.5rem;
  }

  .subTitle {
    font-size: 1rem;
    font-family: OPPOSans;
    font-weight: normal;
    color: #666666;
    margin: 1rem 0 3.5rem 0;
  }

  .text {
    font-size: 1.125;
    font-family: OPPOSans;
    font-weight: normal;
    color: #333333;
    margin: 1.5rem 0 5.375rem 0;
  }
  .bottom {
    width: 100%;
    height: 3.5rem;
    background: #2a2313;
    border-radius: 0 0 4px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .el-dialog__body {
  padding: 0;
}
</style>
